
<template>
  <div id="HeaderNav">
    <ul class="quickMenu">
      <li @click="jumpPage(item)" v-for="(item, index) in menuList" :key="index">
        <div class="img_bg">
          <div class="icon">
            <img :src="item.icon" alt="" />
          </div>
          <!-- <p>{{ item.name }}</p> -->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      menuList: [
        /* {
          name: this.$t('m_home.hbdl'),
          icon: "https://static.flsystem199.com/tc-file/2a859231662c192521e6644ff32d4b81.png",
          path: "/exchangeRate",
        }, */
        // {
        //   name: this.$t('m_home.zx'),
        //   icon: require('../../../assets/new_img/icon-15.png'),
        //   path: "/information",
        // },
        // {
        //   name: this.$t('m_home.zxkf'),
        //   icon: "https://static.flsystem199.com/tc-file/26d5e580351d900fc32e56bc69f4b644.png",
        //   path: "/cusService",
        // },
        {
          name: this.$t('m_settings.yysz'),
          icon: require('../../../assets/new_img/lang.png'),
          path: "/langsetting",
        },
        // {
        //   name: this.$t('m_home.czck'),
        //   icon: require('../../../assets/new_img/icon-13.png'),
        //   path: "/recharge",
        // },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log("进来了1")
  },
  methods: {
    jumpPage(item) {
      console.log("进来了")
      this.$router.push(item.path);
    },
  }
};
</script>
<style scoped lang="less">
.quickMenu {
  // margin-top: 0.2rem;
  text-align: center;
  font-size: 0.27975rem;
  display: flex;

  li {
    padding-top: 0.2rem;
	padding-left: 0.2rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;


    .img_bg {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .icon {
      // width: 0.9rem;
      // height: 0.9rem;
      // border-radius: 50%;
      overflow: hidden;
      /* background-color: #31385a; */
      /* background-image: linear-gradient(90deg, #2948ff, #396afc); */
      display: flex;
      align-items: center;
      justify-content: center;
      // border: 2px solid #e2eef3;
      // box-shadow: 0 0 6px #e2eef3;
    }

    img {
      width: 0.9rem;
      height: 0.9rem;
      display: inline-block;
    }

    p {
      color: #fff8e9;
      line-height: 0.5rem;
      font-size: 0.28rem;
    }

    div {
      display: inline-block;
    }
  }
}

/* @import url(); 引入css类 */
</style>